@charset "UTF-8";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder;
}
.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.cal-month-view .cal-days {
  border: 1px solid;
  border-bottom: 0;
}
.cal-month-view .cal-cell-top {
  min-height: 78px;
  flex: 1;
}
.cal-month-view .cal-cell-row {
  display: flex;
}
.cal-month-view .cal-cell {
  float: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.cal-month-view .cal-cell .cal-event {
  pointer-events: all !important;
}
.cal-month-view .cal-day-cell {
  min-height: 100px;
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid;
}
[dir=rtl] .cal-month-view .cal-day-cell:not(:last-child) {
  border-right: initial;
  border-left: 1px solid;
}
.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid;
}
.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
  float: left;
}
.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
}
.cal-month-view .cal-events {
  flex: 1;
  align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: flex;
  flex-wrap: wrap;
}
.cal-month-view .cal-event {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}
.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer;
}
.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.1;
  cursor: default;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em;
}
.cal-month-view .cal-open-day-events {
  padding: 15px;
}
.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px;
}
.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3;
}
.cal-month-view .cal-draggable {
  cursor: move;
}
.cal-month-view .cal-drag-active * {
  pointer-events: none;
}
.cal-month-view .cal-event-title {
  cursor: pointer;
}
.cal-month-view .cal-event-title:hover {
  text-decoration: underline;
}

.cal-month-view {
  background-color: #fff;
}
.cal-month-view .cal-cell-row:hover {
  background-color: #fafafa;
}
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #ededed;
}
.cal-month-view .cal-days {
  border-color: #e1e1e1;
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #e1e1e1;
}
[dir=rtl] .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: initial;
  border-left-color: #e1e1e1;
}
.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #e1e1e1;
}
.cal-month-view .cal-day-badge {
  background-color: #b94a48;
  color: #fff;
}
.cal-month-view .cal-event {
  background-color: #1e90ff;
  border-color: #d1e8ff;
  color: #fff;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #8b0000;
}
.cal-month-view .cal-day-cell.cal-today {
  background-color: #e8fde7;
}
.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #e0e0e0 !important;
}
.cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #555;
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.cal-week-view {
  /* stylelint-disable-next-line selector-type-no-unknown */
}
.cal-week-view * {
  box-sizing: border-box;
}
.cal-week-view .cal-day-headers {
  display: flex;
  padding-left: 70px;
  border: 1px solid;
}
[dir=rtl] .cal-week-view .cal-day-headers {
  padding-left: initial;
  padding-right: 70px;
}
.cal-week-view .cal-day-headers .cal-header {
  flex: 1;
  text-align: center;
  padding: 5px;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: initial;
  border-left: 1px solid;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: 1px solid;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: initial;
  border-right: 1px solid;
}
.cal-week-view .cal-day-headers span {
  font-weight: 400;
  opacity: 0.5;
}
.cal-week-view .cal-day-column {
  flex-grow: 1;
  border-left: solid 1px;
}
[dir=rtl] .cal-week-view .cal-day-column {
  border-left: initial;
  border-right: solid 1px;
}
.cal-week-view .cal-event {
  font-size: 12px;
  border: 1px solid;
  direction: ltr;
}
.cal-week-view .cal-time-label-column {
  width: 70px;
  height: 100%;
}
.cal-week-view .cal-current-time-marker {
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 2;
}
.cal-week-view .cal-all-day-events {
  border: solid 1px;
  border-top: 0;
  border-bottom-width: 3px;
  padding-top: 3px;
  position: relative;
}
.cal-week-view .cal-all-day-events .cal-day-columns {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 0;
}
.cal-week-view .cal-all-day-events .cal-events-row {
  position: relative;
  height: 31px;
  margin-left: 70px;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-events-row {
  margin-left: initial;
  margin-right: 70px;
}
.cal-week-view .cal-all-day-events .cal-event-container {
  display: inline-block;
  position: absolute;
}
.cal-week-view .cal-all-day-events .cal-event-container.resize-active {
  z-index: 1;
  pointer-events: none;
}
.cal-week-view .cal-all-day-events .cal-event {
  padding: 0 5px;
  margin-left: 2px;
  margin-right: 2px;
  height: 28px;
  line-height: 28px;
}
.cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
  border-top-right-radius: initial;
  border-bottom-right-radius: initial;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cal-week-view .cal-all-day-events .cal-time-label-column {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.cal-week-view .cal-all-day-events .cal-resize-handle {
  width: 6px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  top: 0;
}
.cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
  right: 0;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
  right: initial;
  left: 0;
}
.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cal-week-view .cal-drag-active {
  pointer-events: none;
  z-index: 1;
}
.cal-week-view .cal-drag-active * {
  pointer-events: none;
}
.cal-week-view .cal-time-events {
  position: relative;
  border: solid 1px;
  border-top: 0;
  display: flex;
}
.cal-week-view .cal-time-events .cal-day-columns {
  display: flex;
  flex-grow: 1;
}
.cal-week-view .cal-time-events .cal-day-column {
  position: relative;
}
.cal-week-view .cal-time-events .cal-events-container {
  position: relative;
}
.cal-week-view .cal-time-events .cal-event-container {
  position: absolute;
  z-index: 1;
}
.cal-week-view .cal-time-events .cal-event {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
  padding: 0 5px;
  line-height: 25px;
}
.cal-week-view .cal-time-events .cal-resize-handle {
  width: 100%;
  height: 4px;
  cursor: row-resize;
  position: absolute;
}
.cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
  bottom: 0;
}
.cal-week-view .cal-hour-segment {
  position: relative;
}
.cal-week-view .cal-hour-segment::after {
  content: " ";
}
.cal-week-view .cal-event-container:not(.cal-draggable) {
  cursor: pointer;
}
.cal-week-view .cal-draggable {
  cursor: move;
}
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
  display: block;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: thin dashed;
}
.cal-week-view .cal-time {
  font-weight: bold;
  padding-top: 5px;
  width: 70px;
  text-align: center;
}
.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: none;
}
.cal-week-view .cal-starts-within-day .cal-event {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.cal-week-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cal-week-view {
  background-color: #fff;
  border-top: solid 1px #e1e1e1;
}
.cal-week-view .cal-day-headers {
  border-color: #e1e1e1;
  border-top: 0;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: #e1e1e1;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: initial;
  border-left: solid 1px #e1e1e1 !important;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: #e1e1e1;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: initial;
  border-right-color: #e1e1e1;
}
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #ededed;
}
.cal-week-view .cal-day-column {
  border-left-color: #e1e1e1;
}
[dir=rtl] .cal-week-view .cal-day-column {
  border-left-color: initial;
  border-right-color: #e1e1e1;
}
.cal-week-view .cal-event {
  background-color: #d1e8ff;
  border-color: #1e90ff;
  color: #1e90ff;
}
.cal-week-view .cal-all-day-events {
  border-color: #e1e1e1;
}
.cal-week-view .cal-header.cal-today {
  background-color: #e8fde7;
}
.cal-week-view .cal-header.cal-weekend span {
  color: #8b0000;
}
.cal-week-view .cal-time-events {
  border-color: #e1e1e1;
}
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: #ededed;
}
.cal-week-view .cal-hour-odd {
  background-color: #fafafa;
}
.cal-week-view .cal-drag-over .cal-hour-segment {
  background-color: #ededed;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: #e1e1e1;
}
.cal-week-view .cal-current-time-marker {
  background-color: #ea4334;
}

.cal-day-view {
  /* stylelint-disable-next-line selector-type-no-unknown */
}
.cal-day-view mwl-calendar-week-view-header {
  display: none;
}
.cal-day-view .cal-events-container {
  margin-left: 70px;
}
[dir=rtl] .cal-day-view .cal-events-container {
  margin-left: initial;
  margin-right: 70px;
}
.cal-day-view .cal-day-column {
  border-left: 0;
}
.cal-day-view .cal-current-time-marker {
  margin-left: 70px;
  width: calc(100% - 70px);
}
[dir=rtl] .cal-day-view .cal-current-time-marker {
  margin-left: initial;
  margin-right: 70px;
}

.cal-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 11px;
  word-wrap: break-word;
  opacity: 0.9;
}

.cal-tooltip.cal-tooltip-top {
  padding: 5px 0;
  margin-top: -3px;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
}

.cal-tooltip.cal-tooltip-right {
  padding: 0 5px;
  margin-left: 3px;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
}

.cal-tooltip.cal-tooltip-bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
}

.cal-tooltip.cal-tooltip-left {
  padding: 0 5px;
  margin-left: -3px;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
}

.cal-tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  text-align: center;
  border-radius: 0.25rem;
}

.cal-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  border-top-color: #000;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  border-right-color: #000;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  border-bottom-color: #000;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  border-left-color: #000;
}

.cal-tooltip-inner {
  color: #fff;
  background-color: #000;
}

/* $config: mat-typography-config(
  $font-family: 'Roboto, sans-serif',
); */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Open Sans, sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Open Sans, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Open Sans, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Open Sans, sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Open Sans, sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Open Sans, sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Open Sans, sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Open Sans, sans-serif;
}

.mat-card {
  font-family: Open Sans, sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Open Sans, sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Open Sans, sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Open Sans, sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Open Sans, sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Open Sans, sans-serif;
}

.mat-select {
  font-family: Open Sans, sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Open Sans, sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Open Sans, sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Open Sans, sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Open Sans, sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Open Sans, sans-serif;
}

.mat-list-option {
  font-family: Open Sans, sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Open Sans, sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Open Sans, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.theme-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-option {
  color: white;
}
.theme-dark .mat-option:hover:not(.mat-option-disabled), .theme-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.theme-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #005ec1;
}
.theme-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2f0475;
}
.theme-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff0051;
}
.theme-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.theme-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #005ec1;
}
.theme-dark .mat-pseudo-checkbox-checked,
.theme-dark .mat-pseudo-checkbox-indeterminate,
.theme-dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #2f0475;
}
.theme-dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff0051;
}
.theme-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-dark .mat-app-background, .theme-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.theme-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.theme-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.theme-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-dark .mat-badge {
  position: relative;
}
.theme-dark .mat-badge.mat-badge {
  overflow: visible;
}
.theme-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-dark .ng-animate-disabled .mat-badge-content,
.theme-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-dark .mat-badge-content {
  color: white;
  background: #005ec1;
}
.cdk-high-contrast-active .theme-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-dark .mat-badge-accent .mat-badge-content {
  background: #2f0475;
  color: white;
}
.theme-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff0051;
}
.theme-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-dark .mat-button, .theme-dark .mat-icon-button, .theme-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-dark .mat-button.mat-primary, .theme-dark .mat-icon-button.mat-primary, .theme-dark .mat-stroked-button.mat-primary {
  color: #005ec1;
}
.theme-dark .mat-button.mat-accent, .theme-dark .mat-icon-button.mat-accent, .theme-dark .mat-stroked-button.mat-accent {
  color: #2f0475;
}
.theme-dark .mat-button.mat-warn, .theme-dark .mat-icon-button.mat-warn, .theme-dark .mat-stroked-button.mat-warn {
  color: #ff0051;
}
.theme-dark .mat-button.mat-primary.mat-button-disabled, .theme-dark .mat-button.mat-accent.mat-button-disabled, .theme-dark .mat-button.mat-warn.mat-button-disabled, .theme-dark .mat-button.mat-button-disabled.mat-button-disabled, .theme-dark .mat-icon-button.mat-primary.mat-button-disabled, .theme-dark .mat-icon-button.mat-accent.mat-button-disabled, .theme-dark .mat-icon-button.mat-warn.mat-button-disabled, .theme-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-dark .mat-stroked-button.mat-primary.mat-button-disabled, .theme-dark .mat-stroked-button.mat-accent.mat-button-disabled, .theme-dark .mat-stroked-button.mat-warn.mat-button-disabled, .theme-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-button.mat-primary .mat-button-focus-overlay, .theme-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #005ec1;
}
.theme-dark .mat-button.mat-accent .mat-button-focus-overlay, .theme-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #2f0475;
}
.theme-dark .mat-button.mat-warn .mat-button-focus-overlay, .theme-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff0051;
}
.theme-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-dark .mat-button .mat-ripple-element, .theme-dark .mat-icon-button .mat-ripple-element, .theme-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-dark .mat-button-focus-overlay {
  background: white;
}
.theme-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-flat-button, .theme-dark .mat-raised-button, .theme-dark .mat-fab, .theme-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.theme-dark .mat-flat-button.mat-primary, .theme-dark .mat-raised-button.mat-primary, .theme-dark .mat-fab.mat-primary, .theme-dark .mat-mini-fab.mat-primary {
  color: white;
}
.theme-dark .mat-flat-button.mat-accent, .theme-dark .mat-raised-button.mat-accent, .theme-dark .mat-fab.mat-accent, .theme-dark .mat-mini-fab.mat-accent {
  color: white;
}
.theme-dark .mat-flat-button.mat-warn, .theme-dark .mat-raised-button.mat-warn, .theme-dark .mat-fab.mat-warn, .theme-dark .mat-mini-fab.mat-warn {
  color: white;
}
.theme-dark .mat-flat-button.mat-primary.mat-button-disabled, .theme-dark .mat-flat-button.mat-accent.mat-button-disabled, .theme-dark .mat-flat-button.mat-warn.mat-button-disabled, .theme-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-dark .mat-raised-button.mat-primary.mat-button-disabled, .theme-dark .mat-raised-button.mat-accent.mat-button-disabled, .theme-dark .mat-raised-button.mat-warn.mat-button-disabled, .theme-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-dark .mat-fab.mat-primary.mat-button-disabled, .theme-dark .mat-fab.mat-accent.mat-button-disabled, .theme-dark .mat-fab.mat-warn.mat-button-disabled, .theme-dark .mat-fab.mat-button-disabled.mat-button-disabled, .theme-dark .mat-mini-fab.mat-primary.mat-button-disabled, .theme-dark .mat-mini-fab.mat-accent.mat-button-disabled, .theme-dark .mat-mini-fab.mat-warn.mat-button-disabled, .theme-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-flat-button.mat-primary, .theme-dark .mat-raised-button.mat-primary, .theme-dark .mat-fab.mat-primary, .theme-dark .mat-mini-fab.mat-primary {
  background-color: #005ec1;
}
.theme-dark .mat-flat-button.mat-accent, .theme-dark .mat-raised-button.mat-accent, .theme-dark .mat-fab.mat-accent, .theme-dark .mat-mini-fab.mat-accent {
  background-color: #2f0475;
}
.theme-dark .mat-flat-button.mat-warn, .theme-dark .mat-raised-button.mat-warn, .theme-dark .mat-fab.mat-warn, .theme-dark .mat-mini-fab.mat-warn {
  background-color: #ff0051;
}
.theme-dark .mat-flat-button.mat-primary.mat-button-disabled, .theme-dark .mat-flat-button.mat-accent.mat-button-disabled, .theme-dark .mat-flat-button.mat-warn.mat-button-disabled, .theme-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-dark .mat-raised-button.mat-primary.mat-button-disabled, .theme-dark .mat-raised-button.mat-accent.mat-button-disabled, .theme-dark .mat-raised-button.mat-warn.mat-button-disabled, .theme-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-dark .mat-fab.mat-primary.mat-button-disabled, .theme-dark .mat-fab.mat-accent.mat-button-disabled, .theme-dark .mat-fab.mat-warn.mat-button-disabled, .theme-dark .mat-fab.mat-button-disabled.mat-button-disabled, .theme-dark .mat-mini-fab.mat-primary.mat-button-disabled, .theme-dark .mat-mini-fab.mat-accent.mat-button-disabled, .theme-dark .mat-mini-fab.mat-warn.mat-button-disabled, .theme-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-flat-button.mat-primary .mat-ripple-element, .theme-dark .mat-raised-button.mat-primary .mat-ripple-element, .theme-dark .mat-fab.mat-primary .mat-ripple-element, .theme-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-flat-button.mat-accent .mat-ripple-element, .theme-dark .mat-raised-button.mat-accent .mat-ripple-element, .theme-dark .mat-fab.mat-accent .mat-ripple-element, .theme-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-flat-button.mat-warn .mat-ripple-element, .theme-dark .mat-raised-button.mat-warn .mat-ripple-element, .theme-dark .mat-fab.mat-warn .mat-ripple-element, .theme-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-stroked-button:not([class*=mat-elevation-z]), .theme-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-fab:not([class*=mat-elevation-z]), .theme-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.theme-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.theme-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.theme-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.theme-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.theme-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.theme-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.theme-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.theme-dark .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.theme-dark .mat-card {
  background: #424242;
  color: white;
}
.theme-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.theme-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.theme-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.theme-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #005ec1;
}
.theme-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2f0475;
}
.theme-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff0051;
}
.theme-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.theme-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #005ec1;
}
.theme-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #2f0475;
}
.theme-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff0051;
}
.theme-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.theme-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #005ec1;
  color: white;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff0051;
  color: white;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #2f0475;
  color: white;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-table {
  background: #424242;
}
.theme-dark .mat-table thead, .theme-dark .mat-table tbody, .theme-dark .mat-table tfoot,
.theme-dark mat-header-row, .theme-dark mat-row, .theme-dark mat-footer-row,
.theme-dark [mat-header-row], .theme-dark [mat-row], .theme-dark [mat-footer-row],
.theme-dark .mat-table-sticky {
  background: inherit;
}
.theme-dark mat-row, .theme-dark mat-header-row, .theme-dark mat-footer-row,
.theme-dark th.mat-header-cell, .theme-dark td.mat-cell, .theme-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-cell, .theme-dark .mat-footer-cell {
  color: white;
}
.theme-dark .mat-calendar-arrow {
  fill: white;
}
.theme-dark .mat-datepicker-toggle,
.theme-dark .mat-datepicker-content .mat-calendar-next-button,
.theme-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-calendar-table-header,
.theme-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-calendar-body-cell-content,
.theme-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.theme-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-calendar-body-in-range::before {
  background: rgba(0, 94, 193, 0.2);
}
.theme-dark .mat-calendar-body-comparison-identical,
.theme-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-dark .mat-calendar-body-comparison-bridge-start::before,
.theme-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 94, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-calendar-body-comparison-bridge-end::before,
.theme-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 94, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-dark .mat-calendar-body-selected {
  background-color: #005ec1;
  color: white;
}
.theme-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 94, 193, 0.4);
}
.theme-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 94, 193, 0.3);
}
@media (hover: hover) {
  .theme-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 94, 193, 0.3);
  }
}
.theme-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(47, 4, 117, 0.2);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(47, 4, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(47, 4, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #2f0475;
  color: white;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(47, 4, 117, 0.4);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(47, 4, 117, 0.3);
}
@media (hover: hover) {
  .theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(47, 4, 117, 0.3);
  }
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 81, 0.2);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 81, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 81, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff0051;
  color: white;
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 81, 0.4);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 81, 0.3);
}
@media (hover: hover) {
  .theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 0, 81, 0.3);
  }
}
.theme-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-datepicker-toggle-active {
  color: #005ec1;
}
.theme-dark .mat-datepicker-toggle-active.mat-accent {
  color: #2f0475;
}
.theme-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff0051;
}
.theme-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.theme-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .theme-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.theme-dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-dark .mat-expansion-panel-header-description,
.theme-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-dark .mat-expansion-panel-header {
  height: 48px;
}
.theme-dark .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.theme-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #005ec1;
}
.theme-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #2f0475;
}
.theme-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff0051;
}
.theme-dark .mat-focused .mat-form-field-required-marker {
  color: #2f0475;
}
.theme-dark .mat-form-field-ripple {
  background-color: white;
}
.theme-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #005ec1;
}
.theme-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #2f0475;
}
.theme-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff0051;
}
.theme-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #005ec1;
}
.theme-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #2f0475;
}
.theme-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff0051;
}
.theme-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff0051;
}
.theme-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff0051;
}
.theme-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff0051;
}
.theme-dark .mat-error {
  color: #ff0051;
}
.theme-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #005ec1;
}
.theme-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #2f0475;
}
.theme-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff0051;
}
.theme-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff0051;
}
.theme-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-dark .mat-icon.mat-primary {
  color: #005ec1;
}
.theme-dark .mat-icon.mat-accent {
  color: #2f0475;
}
.theme-dark .mat-icon.mat-warn {
  color: #ff0051;
}
.theme-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-input-element:disabled,
.theme-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-input-element {
  caret-color: #005ec1;
}
.theme-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #2f0475;
}
.theme-dark .mat-form-field.mat-warn .mat-input-element,
.theme-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff0051;
}
.theme-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff0051;
}
.theme-dark .mat-list-base .mat-list-item {
  color: white;
}
.theme-dark .mat-list-base .mat-list-option {
  color: white;
}
.theme-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-list-option:hover, .theme-dark .mat-list-option:focus,
.theme-dark .mat-nav-list .mat-list-item:hover,
.theme-dark .mat-nav-list .mat-list-item:focus,
.theme-dark .mat-action-list .mat-list-item:hover,
.theme-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.theme-dark .mat-list-single-selected-option, .theme-dark .mat-list-single-selected-option:hover, .theme-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-menu-panel {
  background: #424242;
}
.theme-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-dark .mat-menu-item[disabled],
.theme-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.theme-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-menu-item .mat-icon-no-color,
.theme-dark .mat-menu-submenu-icon {
  color: white;
}
.theme-dark .mat-menu-item:hover:not([disabled]),
.theme-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-dark .mat-paginator {
  background: #424242;
}
.theme-dark .mat-paginator,
.theme-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-paginator-decrement,
.theme-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-dark .mat-paginator-first,
.theme-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-dark .mat-icon-button[disabled] .mat-paginator-increment,
.theme-dark .mat-icon-button[disabled] .mat-paginator-first,
.theme-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-paginator-container {
  min-height: 56px;
}
.theme-dark .mat-progress-bar-background {
  fill: #243c54;
}
.theme-dark .mat-progress-bar-buffer {
  background-color: #243c54;
}
.theme-dark .mat-progress-bar-fill::after {
  background-color: #005ec1;
}
.theme-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #302541;
}
.theme-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #302541;
}
.theme-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #2f0475;
}
.theme-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #642438;
}
.theme-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #642438;
}
.theme-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff0051;
}
.theme-dark .mat-progress-spinner circle, .theme-dark .mat-spinner circle {
  stroke: #005ec1;
}
.theme-dark .mat-progress-spinner.mat-accent circle, .theme-dark .mat-spinner.mat-accent circle {
  stroke: #2f0475;
}
.theme-dark .mat-progress-spinner.mat-warn circle, .theme-dark .mat-spinner.mat-warn circle {
  stroke: #ff0051;
}
.theme-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #005ec1;
}
.theme-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #005ec1;
}
.theme-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2f0475;
}
.theme-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2f0475;
}
.theme-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff0051;
}
.theme-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff0051;
}
.theme-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.theme-dark .mat-select-value {
  color: white;
}
.theme-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-select-panel {
  background: #424242;
}
.theme-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #005ec1;
}
.theme-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #2f0475;
}
.theme-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff0051;
}
.theme-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff0051;
}
.theme-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.theme-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.theme-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.theme-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.theme-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2f0475;
}
.theme-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(47, 4, 117, 0.54);
}
.theme-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #2f0475;
}
.theme-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #005ec1;
}
.theme-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 94, 193, 0.54);
}
.theme-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #005ec1;
}
.theme-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff0051;
}
.theme-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 81, 0.54);
}
.theme-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff0051;
}
.theme-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider.mat-primary .mat-slider-track-fill,
.theme-dark .mat-slider.mat-primary .mat-slider-thumb,
.theme-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #005ec1;
}
.theme-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 94, 193, 0.2);
}
.theme-dark .mat-slider.mat-accent .mat-slider-track-fill,
.theme-dark .mat-slider.mat-accent .mat-slider-thumb,
.theme-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #2f0475;
}
.theme-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(47, 4, 117, 0.2);
}
.theme-dark .mat-slider.mat-warn .mat-slider-track-fill,
.theme-dark .mat-slider.mat-warn .mat-slider-thumb,
.theme-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff0051;
}
.theme-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 81, 0.2);
}
.theme-dark .mat-slider:hover .mat-slider-track-background,
.theme-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.theme-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.theme-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-dark .mat-step-header.cdk-keyboard-focused, .theme-dark .mat-step-header.cdk-program-focused, .theme-dark .mat-step-header:hover:not([aria-disabled]), .theme-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-dark .mat-step-header:hover {
    background: none;
  }
}
.theme-dark .mat-step-header .mat-step-label,
.theme-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-dark .mat-step-header .mat-step-icon-selected,
.theme-dark .mat-step-header .mat-step-icon-state-done,
.theme-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #005ec1;
  color: white;
}
.theme-dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #2f0475;
  color: white;
}
.theme-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff0051;
  color: white;
}
.theme-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff0051;
}
.theme-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff0051;
}
.theme-dark .mat-stepper-horizontal, .theme-dark .mat-stepper-vertical {
  background-color: #424242;
}
.theme-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-horizontal-stepper-header::before,
.theme-dark .mat-horizontal-stepper-header::after,
.theme-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-horizontal-stepper-header {
  height: 72px;
}
.theme-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.theme-dark .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.theme-dark .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.theme-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .theme-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.theme-dark .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.theme-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.theme-dark .mat-tab-nav-bar,
.theme-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-dark .mat-tab-label, .theme-dark .mat-tab-link {
  color: white;
}
.theme-dark .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.theme-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-dark .mat-tab-group.mat-primary .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #005ec1;
}
.theme-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-dark .mat-tab-group.mat-accent .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #2f0475;
}
.theme-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-dark .mat-tab-group.mat-warn .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff0051;
}
.theme-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .theme-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #005ec1;
}
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .theme-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #2f0475;
}
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .theme-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff0051;
}
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.theme-dark .mat-toolbar.mat-primary {
  background: #005ec1;
  color: white;
}
.theme-dark .mat-toolbar.mat-accent {
  background: #2f0475;
  color: white;
}
.theme-dark .mat-toolbar.mat-warn {
  background: #ff0051;
  color: white;
}
.theme-dark .mat-toolbar .mat-form-field-underline,
.theme-dark .mat-toolbar .mat-form-field-ripple,
.theme-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-dark .mat-toolbar .mat-form-field-label,
.theme-dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-dark .mat-toolbar .mat-select-value,
.theme-dark .mat-toolbar .mat-select-arrow,
.theme-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-dark .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.theme-dark .mat-toolbar-row, .theme-dark .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .theme-dark .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .theme-dark .mat-toolbar-row, .theme-dark .mat-toolbar-single-row {
    height: 56px;
  }
}
.theme-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.theme-dark .mat-tree {
  background: #424242;
}
.theme-dark .mat-tree-node,
.theme-dark .mat-nested-tree-node {
  color: white;
}
.theme-dark .mat-tree-node {
  min-height: 48px;
}
.theme-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-simple-snackbar-action {
  color: inherit;
}
.theme-dark .cal-month-view {
  background-color: #303030;
}
.theme-dark .cal-month-view .cal-cell-row:hover {
  background-color: #212121;
}
.theme-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.theme-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(0, 26, 53, 0.3647058824);
}
.theme-dark .cal-month-view .cal-days {
  border-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: rgba(0, 0, 0, 0.6);
}
[dir=rtl] .theme-dark .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: initial;
  border-left-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-month-view .cal-day-badge {
  background-color: #b94a48;
  color: #fff;
}
.theme-dark .cal-month-view .cal-event {
  background-color: #212121;
  border-color: #d1e8ff;
  color: #fff;
}
.theme-dark .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: indianred;
}
.theme-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: #212121;
}
.theme-dark .cal-month-view .cal-day-cell.cal-drag-over {
  background-color: rgba(0, 13, 28, 0.3647058824) !important;
}
.theme-dark .cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #212121;
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
}
.theme-dark .cal-week-view {
  background-color: #303030;
  border-top: solid 1px rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-week-view .cal-day-headers {
  border-color: rgba(0, 0, 0, 0.6);
  border-top: 0;
}
.theme-dark .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: rgba(0, 0, 0, 0.6);
}
[dir=rtl] .theme-dark .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: initial;
  border-left: solid 1px rgba(0, 0, 0, 0.6) !important;
}
.theme-dark .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: rgba(0, 0, 0, 0.6);
}
[dir=rtl] .theme-dark .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: initial;
  border-right-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-week-view .cal-day-headers .cal-header:hover,
.theme-dark .cal-week-view .cal-day-headers .cal-drag-over {
  background-color: rgba(0, 26, 53, 0.3647058824);
}
.theme-dark .cal-week-view .cal-day-column {
  border-left-color: rgba(0, 0, 0, 0.6);
}
[dir=rtl] .theme-dark .cal-week-view .cal-day-column {
  border-left-color: initial;
  border-right-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-week-view .cal-event {
  background-color: #d1e8ff;
  border-color: #212121;
  color: #212121;
}
.theme-dark .cal-week-view .cal-all-day-events {
  border-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-week-view .cal-header.cal-today {
  background-color: #212121;
}
.theme-dark .cal-week-view .cal-header.cal-weekend span {
  color: indianred;
}
.theme-dark .cal-week-view .cal-time-events {
  border-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: rgba(0, 26, 53, 0.3647058824);
}
.theme-dark .cal-week-view .cal-hour-odd {
  background-color: #212121;
}
.theme-dark .cal-week-view .cal-drag-over .cal-hour-segment {
  background-color: rgba(0, 26, 53, 0.3647058824);
}
.theme-dark .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.theme-dark .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: rgba(0, 0, 0, 0.6);
}
.theme-dark .cal-week-view .cal-current-time-marker {
  background-color: #ea4334;
}
.theme-dark .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  border-top-color: #000;
}
.theme-dark .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  border-right-color: #000;
}
.theme-dark .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  border-bottom-color: #000;
}
.theme-dark .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  border-left-color: #000;
}
.theme-dark .cal-tooltip-inner {
  color: #fff;
  background-color: #000;
}
.theme-dark .cal-list {
  background-color: #303030;
}
.theme-dark .report-table {
  background-color: #303030;
}

.theme-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-option:hover:not(.mat-option-disabled), .theme-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #005ec1;
}
.theme-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2f0475;
}
.theme-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff0051;
}
.theme-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.theme-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-light .mat-primary .mat-pseudo-checkbox-checked,
.theme-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #005ec1;
}
.theme-light .mat-pseudo-checkbox-checked,
.theme-light .mat-pseudo-checkbox-indeterminate,
.theme-light .mat-accent .mat-pseudo-checkbox-checked,
.theme-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #2f0475;
}
.theme-light .mat-warn .mat-pseudo-checkbox-checked,
.theme-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff0051;
}
.theme-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-light .mat-app-background, .theme-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.theme-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-badge {
  position: relative;
}
.theme-light .mat-badge.mat-badge {
  overflow: visible;
}
.theme-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-light .ng-animate-disabled .mat-badge-content,
.theme-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-light .mat-badge-content {
  color: white;
  background: #005ec1;
}
.cdk-high-contrast-active .theme-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-light .mat-badge-accent .mat-badge-content {
  background: #2f0475;
  color: white;
}
.theme-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff0051;
}
.theme-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-button, .theme-light .mat-icon-button, .theme-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-light .mat-button.mat-primary, .theme-light .mat-icon-button.mat-primary, .theme-light .mat-stroked-button.mat-primary {
  color: #005ec1;
}
.theme-light .mat-button.mat-accent, .theme-light .mat-icon-button.mat-accent, .theme-light .mat-stroked-button.mat-accent {
  color: #2f0475;
}
.theme-light .mat-button.mat-warn, .theme-light .mat-icon-button.mat-warn, .theme-light .mat-stroked-button.mat-warn {
  color: #ff0051;
}
.theme-light .mat-button.mat-primary.mat-button-disabled, .theme-light .mat-button.mat-accent.mat-button-disabled, .theme-light .mat-button.mat-warn.mat-button-disabled, .theme-light .mat-button.mat-button-disabled.mat-button-disabled, .theme-light .mat-icon-button.mat-primary.mat-button-disabled, .theme-light .mat-icon-button.mat-accent.mat-button-disabled, .theme-light .mat-icon-button.mat-warn.mat-button-disabled, .theme-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-light .mat-stroked-button.mat-primary.mat-button-disabled, .theme-light .mat-stroked-button.mat-accent.mat-button-disabled, .theme-light .mat-stroked-button.mat-warn.mat-button-disabled, .theme-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-button.mat-primary .mat-button-focus-overlay, .theme-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #005ec1;
}
.theme-light .mat-button.mat-accent .mat-button-focus-overlay, .theme-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #2f0475;
}
.theme-light .mat-button.mat-warn .mat-button-focus-overlay, .theme-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff0051;
}
.theme-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-light .mat-button .mat-ripple-element, .theme-light .mat-icon-button .mat-ripple-element, .theme-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-light .mat-button-focus-overlay {
  background: black;
}
.theme-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-flat-button, .theme-light .mat-raised-button, .theme-light .mat-fab, .theme-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.theme-light .mat-flat-button.mat-primary, .theme-light .mat-raised-button.mat-primary, .theme-light .mat-fab.mat-primary, .theme-light .mat-mini-fab.mat-primary {
  color: white;
}
.theme-light .mat-flat-button.mat-accent, .theme-light .mat-raised-button.mat-accent, .theme-light .mat-fab.mat-accent, .theme-light .mat-mini-fab.mat-accent {
  color: white;
}
.theme-light .mat-flat-button.mat-warn, .theme-light .mat-raised-button.mat-warn, .theme-light .mat-fab.mat-warn, .theme-light .mat-mini-fab.mat-warn {
  color: white;
}
.theme-light .mat-flat-button.mat-primary.mat-button-disabled, .theme-light .mat-flat-button.mat-accent.mat-button-disabled, .theme-light .mat-flat-button.mat-warn.mat-button-disabled, .theme-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-light .mat-raised-button.mat-primary.mat-button-disabled, .theme-light .mat-raised-button.mat-accent.mat-button-disabled, .theme-light .mat-raised-button.mat-warn.mat-button-disabled, .theme-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-light .mat-fab.mat-primary.mat-button-disabled, .theme-light .mat-fab.mat-accent.mat-button-disabled, .theme-light .mat-fab.mat-warn.mat-button-disabled, .theme-light .mat-fab.mat-button-disabled.mat-button-disabled, .theme-light .mat-mini-fab.mat-primary.mat-button-disabled, .theme-light .mat-mini-fab.mat-accent.mat-button-disabled, .theme-light .mat-mini-fab.mat-warn.mat-button-disabled, .theme-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-flat-button.mat-primary, .theme-light .mat-raised-button.mat-primary, .theme-light .mat-fab.mat-primary, .theme-light .mat-mini-fab.mat-primary {
  background-color: #005ec1;
}
.theme-light .mat-flat-button.mat-accent, .theme-light .mat-raised-button.mat-accent, .theme-light .mat-fab.mat-accent, .theme-light .mat-mini-fab.mat-accent {
  background-color: #2f0475;
}
.theme-light .mat-flat-button.mat-warn, .theme-light .mat-raised-button.mat-warn, .theme-light .mat-fab.mat-warn, .theme-light .mat-mini-fab.mat-warn {
  background-color: #ff0051;
}
.theme-light .mat-flat-button.mat-primary.mat-button-disabled, .theme-light .mat-flat-button.mat-accent.mat-button-disabled, .theme-light .mat-flat-button.mat-warn.mat-button-disabled, .theme-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-light .mat-raised-button.mat-primary.mat-button-disabled, .theme-light .mat-raised-button.mat-accent.mat-button-disabled, .theme-light .mat-raised-button.mat-warn.mat-button-disabled, .theme-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-light .mat-fab.mat-primary.mat-button-disabled, .theme-light .mat-fab.mat-accent.mat-button-disabled, .theme-light .mat-fab.mat-warn.mat-button-disabled, .theme-light .mat-fab.mat-button-disabled.mat-button-disabled, .theme-light .mat-mini-fab.mat-primary.mat-button-disabled, .theme-light .mat-mini-fab.mat-accent.mat-button-disabled, .theme-light .mat-mini-fab.mat-warn.mat-button-disabled, .theme-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-flat-button.mat-primary .mat-ripple-element, .theme-light .mat-raised-button.mat-primary .mat-ripple-element, .theme-light .mat-fab.mat-primary .mat-ripple-element, .theme-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-light .mat-flat-button.mat-accent .mat-ripple-element, .theme-light .mat-raised-button.mat-accent .mat-ripple-element, .theme-light .mat-fab.mat-accent .mat-ripple-element, .theme-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-light .mat-flat-button.mat-warn .mat-ripple-element, .theme-light .mat-raised-button.mat-warn .mat-ripple-element, .theme-light .mat-fab.mat-warn .mat-ripple-element, .theme-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-light .mat-stroked-button:not([class*=mat-elevation-z]), .theme-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-fab:not([class*=mat-elevation-z]), .theme-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.theme-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.theme-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.theme-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.theme-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.theme-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.theme-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.theme-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.theme-light .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.theme-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.theme-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.theme-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.theme-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #005ec1;
}
.theme-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2f0475;
}
.theme-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff0051;
}
.theme-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.theme-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.theme-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.theme-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #005ec1;
}
.theme-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #2f0475;
}
.theme-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff0051;
}
.theme-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.theme-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #005ec1;
  color: white;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff0051;
  color: white;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #2f0475;
  color: white;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-light .mat-table {
  background: white;
}
.theme-light .mat-table thead, .theme-light .mat-table tbody, .theme-light .mat-table tfoot,
.theme-light mat-header-row, .theme-light mat-row, .theme-light mat-footer-row,
.theme-light [mat-header-row], .theme-light [mat-row], .theme-light [mat-footer-row],
.theme-light .mat-table-sticky {
  background: inherit;
}
.theme-light mat-row, .theme-light mat-header-row, .theme-light mat-footer-row,
.theme-light th.mat-header-cell, .theme-light td.mat-cell, .theme-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-cell, .theme-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-datepicker-toggle,
.theme-light .mat-datepicker-content .mat-calendar-next-button,
.theme-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-calendar-table-header,
.theme-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-calendar-body-cell-content,
.theme-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.theme-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.theme-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.theme-light .mat-calendar-body-in-range::before {
  background: rgba(0, 94, 193, 0.2);
}
.theme-light .mat-calendar-body-comparison-identical,
.theme-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-light .mat-calendar-body-comparison-bridge-start::before,
.theme-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 94, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-light .mat-calendar-body-comparison-bridge-end::before,
.theme-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 94, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-light .mat-calendar-body-selected {
  background-color: #005ec1;
  color: white;
}
.theme-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 94, 193, 0.4);
}
.theme-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 94, 193, 0.3);
}
@media (hover: hover) {
  .theme-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 94, 193, 0.3);
  }
}
.theme-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(47, 4, 117, 0.2);
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(47, 4, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(47, 4, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #2f0475;
  color: white;
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(47, 4, 117, 0.4);
}
.theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(47, 4, 117, 0.3);
}
@media (hover: hover) {
  .theme-light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(47, 4, 117, 0.3);
  }
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 81, 0.2);
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 81, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 81, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff0051;
  color: white;
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 81, 0.4);
}
.theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 81, 0.3);
}
@media (hover: hover) {
  .theme-light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 0, 81, 0.3);
  }
}
.theme-light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-datepicker-toggle-active {
  color: #005ec1;
}
.theme-light .mat-datepicker-toggle-active.mat-accent {
  color: #2f0475;
}
.theme-light .mat-datepicker-toggle-active.mat-warn {
  color: #ff0051;
}
.theme-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .theme-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-expansion-panel-header-description,
.theme-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-light .mat-expansion-panel-header {
  height: 48px;
}
.theme-light .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.theme-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.theme-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #005ec1;
}
.theme-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #2f0475;
}
.theme-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff0051;
}
.theme-light .mat-focused .mat-form-field-required-marker {
  color: #2f0475;
}
.theme-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #005ec1;
}
.theme-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #2f0475;
}
.theme-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff0051;
}
.theme-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #005ec1;
}
.theme-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #2f0475;
}
.theme-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff0051;
}
.theme-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff0051;
}
.theme-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff0051;
}
.theme-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff0051;
}
.theme-light .mat-error {
  color: #ff0051;
}
.theme-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.theme-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #005ec1;
}
.theme-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #2f0475;
}
.theme-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff0051;
}
.theme-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff0051;
}
.theme-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.theme-light .mat-icon.mat-primary {
  color: #005ec1;
}
.theme-light .mat-icon.mat-accent {
  color: #2f0475;
}
.theme-light .mat-icon.mat-warn {
  color: #ff0051;
}
.theme-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-input-element:disabled,
.theme-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-input-element {
  caret-color: #005ec1;
}
.theme-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #2f0475;
}
.theme-light .mat-form-field.mat-warn .mat-input-element,
.theme-light .mat-form-field-invalid .mat-input-element {
  caret-color: #ff0051;
}
.theme-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff0051;
}
.theme-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-list-option:hover, .theme-light .mat-list-option:focus,
.theme-light .mat-nav-list .mat-list-item:hover,
.theme-light .mat-nav-list .mat-list-item:focus,
.theme-light .mat-action-list .mat-list-item:hover,
.theme-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.theme-light .mat-list-single-selected-option, .theme-light .mat-list-single-selected-option:hover, .theme-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-menu-panel {
  background: white;
}
.theme-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-menu-item[disabled],
.theme-light .mat-menu-item[disabled] .mat-menu-submenu-icon,
.theme-light .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-menu-item .mat-icon-no-color,
.theme-light .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-menu-item:hover:not([disabled]),
.theme-light .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-light .mat-paginator {
  background: white;
}
.theme-light .mat-paginator,
.theme-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-paginator-decrement,
.theme-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-light .mat-paginator-first,
.theme-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-light .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-light .mat-icon-button[disabled] .mat-paginator-increment,
.theme-light .mat-icon-button[disabled] .mat-paginator-first,
.theme-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-paginator-container {
  min-height: 56px;
}
.theme-light .mat-progress-bar-background {
  fill: #bcd3ec;
}
.theme-light .mat-progress-bar-buffer {
  background-color: #bcd3ec;
}
.theme-light .mat-progress-bar-fill::after {
  background-color: #005ec1;
}
.theme-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c7bdd9;
}
.theme-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c7bdd9;
}
.theme-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #2f0475;
}
.theme-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbbcd0;
}
.theme-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbbcd0;
}
.theme-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff0051;
}
.theme-light .mat-progress-spinner circle, .theme-light .mat-spinner circle {
  stroke: #005ec1;
}
.theme-light .mat-progress-spinner.mat-accent circle, .theme-light .mat-spinner.mat-accent circle {
  stroke: #2f0475;
}
.theme-light .mat-progress-spinner.mat-warn circle, .theme-light .mat-spinner.mat-warn circle {
  stroke: #ff0051;
}
.theme-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #005ec1;
}
.theme-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #005ec1;
}
.theme-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2f0475;
}
.theme-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2f0475;
}
.theme-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff0051;
}
.theme-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff0051;
}
.theme-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.theme-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-select-panel {
  background: white;
}
.theme-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #005ec1;
}
.theme-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #2f0475;
}
.theme-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff0051;
}
.theme-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff0051;
}
.theme-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.theme-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.theme-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2f0475;
}
.theme-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(47, 4, 117, 0.54);
}
.theme-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #2f0475;
}
.theme-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #005ec1;
}
.theme-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 94, 193, 0.54);
}
.theme-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #005ec1;
}
.theme-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff0051;
}
.theme-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 81, 0.54);
}
.theme-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff0051;
}
.theme-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.theme-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.theme-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-slider.mat-primary .mat-slider-track-fill,
.theme-light .mat-slider.mat-primary .mat-slider-thumb,
.theme-light .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #005ec1;
}
.theme-light .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-light .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 94, 193, 0.2);
}
.theme-light .mat-slider.mat-accent .mat-slider-track-fill,
.theme-light .mat-slider.mat-accent .mat-slider-thumb,
.theme-light .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #2f0475;
}
.theme-light .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-light .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(47, 4, 117, 0.2);
}
.theme-light .mat-slider.mat-warn .mat-slider-track-fill,
.theme-light .mat-slider.mat-warn .mat-slider-thumb,
.theme-light .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff0051;
}
.theme-light .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-light .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 81, 0.2);
}
.theme-light .mat-slider:hover .mat-slider-track-background,
.theme-light .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-slider.mat-slider-disabled .mat-slider-track-background,
.theme-light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.theme-light .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.theme-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.theme-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.theme-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.theme-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.theme-light .mat-step-header.cdk-keyboard-focused, .theme-light .mat-step-header.cdk-program-focused, .theme-light .mat-step-header:hover:not([aria-disabled]), .theme-light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-light .mat-step-header:hover {
    background: none;
  }
}
.theme-light .mat-step-header .mat-step-label,
.theme-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.theme-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.theme-light .mat-step-header .mat-step-icon-selected,
.theme-light .mat-step-header .mat-step-icon-state-done,
.theme-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #005ec1;
  color: white;
}
.theme-light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #2f0475;
  color: white;
}
.theme-light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff0051;
  color: white;
}
.theme-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff0051;
}
.theme-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff0051;
}
.theme-light .mat-stepper-horizontal, .theme-light .mat-stepper-vertical {
  background-color: white;
}
.theme-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-horizontal-stepper-header::before,
.theme-light .mat-horizontal-stepper-header::after,
.theme-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .mat-horizontal-stepper-header {
  height: 72px;
}
.theme-light .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.theme-light .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.theme-light .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.theme-light .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .theme-light .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.theme-light .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.theme-light .mat-sort-header-arrow {
  color: #757575;
}
.theme-light .mat-tab-nav-bar,
.theme-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.theme-light .mat-tab-label, .theme-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.theme-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-light .mat-tab-group.mat-primary .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #005ec1;
}
.theme-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-light .mat-tab-group.mat-accent .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #2f0475;
}
.theme-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-light .mat-tab-group.mat-warn .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff0051;
}
.theme-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header, .theme-light .mat-tab-group.mat-background-primary > .mat-tab-link-container, .theme-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #005ec1;
}
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header, .theme-light .mat-tab-group.mat-background-accent > .mat-tab-link-container, .theme-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #2f0475;
}
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(70, 193, 164, 0.3);
}
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header, .theme-light .mat-tab-group.mat-background-warn > .mat-tab-link-container, .theme-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff0051;
}
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-toolbar.mat-primary {
  background: #005ec1;
  color: white;
}
.theme-light .mat-toolbar.mat-accent {
  background: #2f0475;
  color: white;
}
.theme-light .mat-toolbar.mat-warn {
  background: #ff0051;
  color: white;
}
.theme-light .mat-toolbar .mat-form-field-underline,
.theme-light .mat-toolbar .mat-form-field-ripple,
.theme-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-light .mat-toolbar .mat-form-field-label,
.theme-light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-light .mat-toolbar .mat-select-value,
.theme-light .mat-toolbar .mat-select-arrow,
.theme-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-light .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.theme-light .mat-toolbar-row, .theme-light .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .theme-light .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .theme-light .mat-toolbar-row, .theme-light .mat-toolbar-single-row {
    height: 56px;
  }
}
.theme-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.theme-light .mat-tree {
  background: white;
}
.theme-light .mat-tree-node,
.theme-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .mat-tree-node {
  min-height: 48px;
}
.theme-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-light .mat-simple-snackbar-action {
  color: #2f0475;
}

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  min-height: 100vh;
}

.full-height {
  height: 100%;
  min-height: 100vh;
}

.margin-25 {
  margin: 25px;
}

.padding-25 {
  padding: 25px;
}

.img-responsive {
  width: 100%;
  height: auto;
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.mat-form-field .mat-error:not(:first-child) {
  display: none;
}

.img-avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 14px 14px 10px 4px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

mat-sidenav-container mat-sidenav {
  width: 300px;
  overflow-x: hidden;
}

.page-header {
  height: 85px !important;
}
.page-header h1 {
  padding-left: 12px;
  font-weight: 300;
  margin: 0;
  font-size: 20px;
}

esc-dashboard .widget {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 80%;
}

esc-pages .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0px !important;
}

.height-90 {
  height: 90vh;
}

.spacer {
  flex: 1 1 auto;
  text-align: center;
}

.count-margin {
  margin: 10px;
}

.check-all-margin {
  margin-left: 16px;
}

.mat-list-item.selected {
  background: rgba(0, 0, 0, 0.1);
}
.mat-list-item.selected .mat-line {
  font-weight: bold !important;
}
.mat-list-item.selected .nav-list-div:first-child {
  color: rgb(3, 169, 244);
}

.float-right {
  float: right;
  margin-right: 10px;
}

h3 {
  font-weight: 500 !important;
  font-size: 14px !important;
}

p {
  font-size: 12px !important;
}

mat-nav-list {
  padding-top: 0px !important;
}

.hideList {
  display: none !important;
}

.ng-star-inserted-full-height > .ng-star-inserted {
  flex: 1 1 auto !important;
  display: flex !important;
  overflow: hidden !important;
}

.mat-chip-float {
  position: relative;
  right: 22px;
  top: -26px;
  z-index: 1;
  width: 0px;
}
.mat-chip-float .mat-chip-list-wrapper {
  position: absolute;
}

.search-field {
  width: 250px;
}
.search-field mat-icon {
  font-size: 15px;
}

.search-field-lt-sm {
  width: 100%;
}

.report-container {
  height: 100%;
  width: 100%;
}

.dialog-no-padding .mat-dialog-container {
  padding: 0 !important;
}

.carousel-control {
  display: none !important;
}

* {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.full-width-tabs .mat-tab-labels {
  display: flex;
  justify-content: space-between;
}

.full-width-tabs .mat-tab-label {
  flex-grow: 1;
  text-align: center;
}

.text-right {
  text-align: right;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/Icon1.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/iconoutlined.woff2) format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(/assets/symbols1.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}